import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { createEvent } from '~/utils/eventable';
import { TextFieldGroup, SubmitGroup } from '../../admin/shared/forms/BasicForm';
import { notBlankValidator } from '../../utils/forms';

const AuthenticationPageFields = ({
  classes,
  values,
  setUserInput,
}) => {
  const intl = useIntl();

  return (
    <React.Fragment>
      <TextFieldGroup
        inputProps={{ 'aria-required': 'true' }}
        data-testid="v2-user-input-field"
        className={classes.emailFieldContainer}
        fullWidth
        field="userInput"
        name="userInput"
        title={intl.formatMessage({
          defaultMessage: 'Email or Phone Number',
          id: 'sessions.email_or_phone',
        })}
        validate={notBlankValidator}
      />
      <SubmitGroup
        block
        variant="primary"
        disabled={!values.userInput}
        fullWidth
        onClick={() => {
          createEvent({
            eventableType: 'BecomeVipV2',
            eventType: 'v2_continue_sign_in_button',
          });
          setUserInput(values.userInput);
        }}
        title={(<FormattedMessage id="follow.join_button" defaultMessage="Continue" />)}
      />
    </React.Fragment>
  );
};

AuthenticationPageFields.propTypes = {
  classes: PropTypes.object.isRequired,
  setUserInput: PropTypes.func.isRequired,
  values: PropTypes.shape({
    userInput: PropTypes.string,
  }).isRequired,
};

export default AuthenticationPageFields;
