import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { compose, mapProps } from '@shakacode/recompose';
import { useHistory } from 'react-router-dom';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import { withCurrentSession } from '../CurrentSessionProvider';
import { withRestaurant } from '../../utils/withRestaurant';
import { withStyles } from '../../utils/withStyles';
import { getParam } from '../../utils/urls';

import { closeUserEmailCheckPrompt, closeVipV2Modal, setDidSubmitEmailWithFollowPrompt, setVipData, openProfileModal, openProfileV2Modal, openFavoriteLocationModal } from '../ModalActions';

import VipV2ModalStyles from './styles';
import { useFeatureFlags } from '../../utils/featureFlagsContext';
import { getUserEntryPoint } from '../sessions/utils';
import VipV2Router from './VipV2Router';
import VipV2MobileDrawer from './VipV2MobileDrawer';
import VipV2ModalContent from './VipV2ModalContent';

const VipV2Modal = ({ classes, isLoggedIn, refetchUser, width, restaurant }) => {
  const history = useHistory();
  const [googleAnalyticsCloseTitle, setGoogleAnalyticsCloseTitle] = useState('');
  const [useAccessCode, setUseAccessCode] = useState(false);
  const [usePhoneSignInPage, setUsePhoneSignInPage] = useState(false);
  const [useValidationAccessCode, setUseValidationAccessCode] = useState(false);
  const [useDifferentNumber, setUseDifferentNumber] = useState(false);
  const [useMagicLink, setUseMagicLink] = useState(false);
  const [userData, setUserData] = useState();
  const [newUserName, setNewUserName] = useState();
  const [showFollowerInfoPage, setShowFollowerInfoPage] = useState(true);
  const [favoriteLocationId, setFavoriteLocationId] = useState();
  const [newUserPhone, setNewUserPhone] = useState();

  const showVipV2Modal = useSelector(state => state.modals.showVipV2Modal);
  const vipData = useSelector(state => state.modals.vipData);

  const { isBecomeVipModal, userEmail, userPhone } = vipData;

  const { isFeatureActive } = useFeatureFlags();
  const isLoyaltyEnabled = isFeatureActive('isLoyaltyEnabled');

  const dispatch = useDispatch();

  const closeModal = () => {
    setUseAccessCode(false);
    setUseDifferentNumber(false);
    setUseMagicLink(false);
    setUseValidationAccessCode(false);
    setShowFollowerInfoPage(true);
    setNewUserPhone(null);
    dispatch(setDidSubmitEmailWithFollowPrompt(false));
    dispatch(setVipData());
    dispatch(closeVipV2Modal());
    if (getParam('magic_link') === 'show') {
      history.push('/');
    }
  };

  const backgroundImageUrl = restaurant.signUpBackgroundImageUrl ||
  restaurant.photoUrl ||
  (restaurant.homePage && restaurant.homePage.heroImages && restaurant.homePage.heroImages[0] && restaurant.homePage.heroImages[0].imageUrl);

  const followerSource = useMemo(() => getUserEntryPoint(
    isBecomeVipModal,
  ), [isBecomeVipModal]);

  const goToFavoriteLocationModal = () => {
    closeModal();
    dispatch(openFavoriteLocationModal());
  };

  const goToProfileModal = (tab) => {
    closeModal();
    if (restaurant && restaurant.featureSetting && restaurant.featureSetting.isProfileV2Enabled) {
      dispatch(openProfileV2Modal(tab));
    } else {
      dispatch(openProfileModal());
    }
  };

  const displayForm = (data, loading) => (
    <VipV2Router
      closeModal={closeModal}
      favoriteLocationId={favoriteLocationId}
      followerSource={followerSource}
      goToFavoriteLocationModal={goToFavoriteLocationModal}
      goToProfileModal={goToProfileModal}
      isLoyaltyEnabled={isLoyaltyEnabled}
      isLoggedIn={isLoggedIn}
      loading={loading}
      newUserName={newUserName}
      newUserPhone={newUserPhone}
      refetchUser={refetchUser}
      restaurant={restaurant}
      setFavoriteLocationId={setFavoriteLocationId}
      setGoogleAnalyticsCloseTitle={setGoogleAnalyticsCloseTitle}
      setNewUserName={setNewUserName}
      setNewUserPhone={setNewUserPhone}
      setShowFollowerInfoPage={setShowFollowerInfoPage}
      setUseAccessCode={setUseAccessCode}
      setUseDifferentNumber={setUseDifferentNumber}
      setUseMagicLink={setUseMagicLink}
      setUseValidationAccessCode={setUseValidationAccessCode}
      setUsePhoneSignInPage={setUsePhoneSignInPage}
      setUserData={setUserData}
      showFollowerInfoPage={showFollowerInfoPage}
      useAccessCode={useAccessCode}
      useDifferentNumber={useDifferentNumber}
      useMagicLink={useMagicLink}
      useValidationAccessCode={useValidationAccessCode}
      usePhoneSignInPage={usePhoneSignInPage}
      userData={data}
      vipData={vipData}
    />
  );

  const isMobile = isWidthDown('md', width);

  if (!showVipV2Modal) {
    return null;
  }

  if (isMobile) {
    return (
      <VipV2MobileDrawer
        classes={classes}
        closeModal={closeModal}
        closeUserEmailCheckPrompt={() => dispatch(closeUserEmailCheckPrompt())}
        displayForm={displayForm}
        restaurant={restaurant}
        setUseValidationAccessCode={setUseValidationAccessCode}
        setUsePhoneSignInPage={setUsePhoneSignInPage}
        userData={userData}
        userEmail={userEmail}
        userPhone={userPhone}
      />
    );
  }
  return (
    <VipV2ModalContent
      classes={classes}
      restaurant={restaurant}
      userEmail={userEmail}
      userPhone={userPhone}
      userData={userData}
      displayForm={displayForm}
      backgroundImageUrl={backgroundImageUrl}
      setNewUserPhone={setNewUserPhone}
      setUseAccessCode={setUseAccessCode}
      setUseValidationAccessCode={setUseValidationAccessCode}
      setUsePhoneSignInPage={setUsePhoneSignInPage}
      showVipV2Modal={showVipV2Modal}
      closeModal={closeModal}
      googleAnalyticsCloseTitle={googleAnalyticsCloseTitle}
    />
  );
};

VipV2Modal.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  restaurant: PropTypes.shape({
    customFollowPath: PropTypes.string,
    followButtonCta: PropTypes.string,
    followHeadingCta: PropTypes.string,
    followSubheadingCta: PropTypes.string,
  }).isRequired,
};

export default compose(
  withCurrentSession,
  withStyles(VipV2ModalStyles),
  withRestaurant,
  mapProps(({ currentSession, ...props }) => ({
    ...props,
    isLoggedIn: !!currentSession.user,
    refetchUser: currentSession.refetch,
  })),
)(withWidth()(VipV2Modal));
