import React from 'react';
import { useField } from 'react-form';
import { useIntl } from 'react-intl';
import { TextFieldGroup } from '~/admin/shared/forms/BasicForm';
import { makeStyles } from '../../utils/withStyles';
import SignUpFormStyles from '../VipV2Modal/styles';

const useStyles = makeStyles(SignUpFormStyles);

const SignUpPhoneOrEmailFormFields = () => {
  const intl = useIntl();
  const classes = useStyles();

  useField('userInput', {
    validate: (value) => {
      if (!value) {
        return intl.formatMessage({ defaultMessage: 'is required', id: 'users.email.error.required' });
      }

      return false;
    },
  });

  return (
    <React.Fragment>
      <TextFieldGroup
        field="userInput"
        FormGroupProps={{ className: classes.emailFieldContainer }}
        InputProps={{ className: classes.emailField, 'data-cy': 'user_input' }}
        placeholder={intl.formatMessage({ defaultMessage: 'Enter Phone or Email', id: 'sessions.phone_number_or_email' })}
      />
    </React.Fragment>
  );
};

export default SignUpPhoneOrEmailFormFields;
