import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useMutation } from '~/lazy_apollo/client';
import { compose } from '@shakacode/recompose';
import { Grid, Avatar } from '@popmenu/common-ui';

import { useSelector } from 'react-redux';
import { createEvent } from '~/utils/eventable';
import { withIntl } from '../../utils/withIntl';
import { withStyles } from '../../utils/withStyles';
import sendUserSmsAccessCodeMutation from '../../libs/gql/mutations/users/sendUserSmsAccessCodeMutation.gql';
import signInStyles from '../VipV2Modal/styles';

import OfferTag from '../../assets/svg/popmenu_guest_profile_offer_tag.svg';
import BasicForm, { SubmitGroup, TextFieldGroup } from '../../admin/shared/forms/BasicForm';
import { AH, AHLevelProvider } from '../../consumer/shared/AccessibleHeading';

const PhoneSignInV2Form = (props) => {
  const userName = props.userData.displayName ? `, ${props.userData.displayName}` : '';
  const offersCount = props.userData.orderingOfferCodesCount;
  const [sendUserSmsAccessCode] = useMutation(sendUserSmsAccessCodeMutation);
  const isOnOloPage = useSelector(state => state.modals.isOnOloPage);

  useEffect(() => {
    if (isOnOloPage && props.userData?.orderingOfferCodesCount > 0) {
      createEvent({
        eventableType: 'GuestProfile',
        eventLabel: 'offer_check | follower | offer | sign_in_modal',
        eventType: 'follower_profile_sign_in_offer_modal',
      });
    } else if (isOnOloPage) {
      createEvent({
        eventableType: 'GuestProfile',
        eventLabel: 'offer_check | follower | no_offer | sign_in_modal',
        eventType: 'follower_profile_sign_in_no_offer_modal',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendAccessCode = () => {
    sendUserSmsAccessCode({
      variables: {
        phone: props.userPhone,
        restaurantId: props.restaurantId,
      },
    }).then(() => {
      props.useAccessCode();
    }).catch((err) => {
      console.warn(err);
    });
  };

  const { avatarUrl } = props.userData;

  const renderForm = () => (
    <BasicForm
      aria-labelledby="sign-in-title"
      defaultValues={{
        phone: props.userPhone,
      }}
      id="sign-in-v2-form"
      labelPosition="top"
      onSubmit={sendAccessCode}
    >
      {({ values }) => {
        const submitDisabled = !values.phone;
        return (
          <React.Fragment>
            <TextFieldGroup
              id="pm-signin-phone-input"
              field="phone"
              title={props.t('users.phone')}
              disabled
              type="phone"
            />
            <React.Fragment>
              <SubmitGroup
                block
                ButtonProps={{ lowercase: true }}
                data-cy="sendAccessCode"
                color="primary"
                disabled={submitDisabled}
                icon={false}
                justify="center"
                size="lg"
                title="Send Access Code"
              />
            </React.Fragment>
          </React.Fragment>
        );
      }}
    </BasicForm>
  );

  return (
    <Grid container>
      {/* New guest profile flow. Below will only render when attempting email check via ProfileV2 olo page and user having offers */}
      {isOnOloPage && props.userData?.orderingOfferCodesCount > 0 ? (
        <React.Fragment>
          <Grid item xs={12} align="center">
            <OfferTag width={60} height="100%" />
          </Grid>
          <Grid item xs={12}>
            <AH typography align="center" className={props.classes.headerTertiary} variant="h3" id="become-vip-title" aria-live="polite" role="status">
              <FormattedMessage
                id="sessions.with_offers.sign_in"
                defaultMessage="Hi {displayName},{br}you have {offersCount} available {offer}!"
                values={{ br: <br />, displayName: props.userData.displayName, offer: offersCount === 1 ? 'offer' : 'offers', offersCount }}
              />
            </AH>
          </Grid>
          <AHLevelProvider>
            <Grid item xs={12} align="center">
              <AH
                typography
                align="center"
                className={props.classes.subheader}
                variant="h5"
                id="become-vip-description"
                aria-live="polite"
                role="status"
              >
                <FormattedMessage
                  id="sessions.with_offers.sign_in_v2_subtitle"
                  defaultMessage="Sign in to explore your new VIP profile and apply {offer}"
                  values={{ offer: offersCount === 1 ? 'this offer to your order' : 'one of them to your order' }}
                />
              </AH>
            </Grid>
          </AHLevelProvider>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Grid item xs={12} align="center" className={props.classes.avatarContainer}>
            <Avatar
              alt={userName || 'avatar'}
              className={props.classes.avatar}
              src={avatarUrl}
            />
          </Grid>
          <Grid item xs={12}>
            <AH typography align="center" className={props.classes.header} variant="h3" id="become-vip-title" aria-live="polite" role="status">
              <FormattedMessage
                id="sessions.sign_in"
                defaultMessage={`Welcome Back${userName}!`}
              />
            </AH>
          </Grid>
          <AHLevelProvider>
            <Grid item xs={12} align="center">
              <AH
                typography
                align="center"
                className={props.classes.subheader}
                variant="h5"
                id="become-vip-description"
                aria-live="polite"
                role="status"
              >
                <FormattedMessage
                  id="sessions.sign_in_v2_subtitle"
                  defaultMessage="Please verify your account"
                />
              </AH>
            </Grid>
          </AHLevelProvider>
        </React.Fragment>
      )}
      <AHLevelProvider>
        <Grid item xs={12} className={props.classes.formContainer}>
          {renderForm()}
        </Grid>
      </AHLevelProvider>
    </Grid>
  );
};

PhoneSignInV2Form.defaultProps = {
  favoriteLocationId: null,
  restaurantId: null,
  style: null,
};

PhoneSignInV2Form.propTypes = {
  favoriteLocationId: PropTypes.number,
  refetchUser: PropTypes.func.isRequired,
  restaurantId: PropTypes.number,
  restaurantName: PropTypes.string.isRequired,
  style: PropTypes.object,
  t: PropTypes.func.isRequired,
  useAccessCode: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  userPhone: PropTypes.string.isRequired,
};

export default compose(
  withStyles(signInStyles),
  withIntl,
)(PhoneSignInV2Form);
