import React from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import AccessCodeSignInForm from '../sessions/AccessCodeSignInForm';
import AccessCodeValidationForm from '../sessions/AccessCodeValidation/AccessCodeValidationForm';
import Loading from '../Loading';
import OneLastStep from '../sessions/AccessCodeValidation/OneLastStep';
import AuthenticationPage from './AuthenticationPage';
import EmailAuthenticationForm from './EmailAuthenticationForm';
import MagicLinkSentPage from './MagicLinkSentPage';
import NewFollowerInfoPage from './NewFollowerInfoPage';
import PhoneSignInV2Form from '../sessions/PhoneSignInV2Form';
import SignInV2Form from '../sessions/SignInV2Form';
import SignInVerifiedPage from './SignInVerifiedPage';
import SignUpV2Form from '../sessions/SignUpV2Form';
import SignUpSuccessPage from './SignUpSuccessPage';
import SignUpPhoneOrEmailForm from '../sessions/SignUpPhoneOrEmailForm';
import { setVipData } from '../ModalActions';

export const goToMagicLinkSentPage = (userData, useMagicLink) => useMagicLink && !!userData;

export const goToNewFollowerPage = (userData, showFollowerInfoPage, isLoggedIn, useValidationAccessCode) => userData && ('isFollower' in userData && !userData.isFollower) && showFollowerInfoPage && !useValidationAccessCode && !isLoggedIn;

export const goToSignUpSuccessPage = (userData, isLoggedIn, userEmail, showSignInForm, isMagicLinkPath, useMagicLink) => isLoggedIn && ((!userData && !isMagicLinkPath) || (!userEmail && !showSignInForm && !isMagicLinkPath && !useMagicLink));

export const goToSignUpFormPage = (userData, isMagicLinkPath, userEmail, userPhone, showSignInForm) => ((userEmail || userPhone) && !userData) && ((!userData && !isMagicLinkPath) || (!showSignInForm && !isMagicLinkPath));

export const goToSignInVerifiedPage = (userData, isLoggedIn) => isLoggedIn && (!!userData || isLoggedIn);

export const goToUseAccessCodePage = (userData, useAccessCode) => useAccessCode && !!userData;

export const goToSignInPage = (userData, isLoggedIn, usePhoneSignInPage) => !usePhoneSignInPage && !isLoggedIn && !!userData;

export const goToPhoneSignInPage = (isLoggedIn, userData, usePhoneSignInPage) => usePhoneSignInPage && !isLoggedIn && !!userData;

export const goToValidationAccessCodePage = (userData, useValidationAccessCode, isLoyaltyEnabled, newUserPhone) => useValidationAccessCode && isLoyaltyEnabled && ((!!userData && !userData.isTextValidated)) || ((newUserPhone && useValidationAccessCode) || (!!userData && userData.isTextValidated && useValidationAccessCode));

export const goToOneLastStepPage = (userData, useValidationAccessCode, isLoyaltyEnabled, newUserPhone, useDifferentNumber) => useValidationAccessCode && isLoyaltyEnabled && ((!newUserPhone && !!userData && !userData.lastFourDigitsPhoneNumber) || useDifferentNumber) && !userData.isTextValidated;

export const goDirectlyToSignup = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('follow') === 'show';
};

const VipV2Router = ({
  closeModal,
  favoriteLocationId,
  followerSource,
  goToFavoriteLocationModal,
  goToProfileModal,
  isLoyaltyEnabled,
  isLoggedIn,
  loading,
  newUserName,
  newUserPhone,
  refetchUser,
  restaurant,
  setFavoriteLocationId,
  setGoogleAnalyticsCloseTitle,
  setNewUserName,
  setNewUserPhone,
  setShowFollowerInfoPage,
  setUseAccessCode,
  setUseDifferentNumber,
  setUseMagicLink,
  setUsePhoneSignInPage,
  setUseValidationAccessCode,
  setUserData,
  showFollowerInfoPage,
  useAccessCode,
  useDifferentNumber,
  useMagicLink,
  usePhoneSignInPage,
  useValidationAccessCode,
  userData,
  vipData,
}) => {
  const dispatch = useDispatch();
  const { isMagicLinkPath, isPopPath, isReviewPath, isBecomeVipModal, userEmail, userPhone, showSignInForm } = vipData;

  if (loading) {
    setGoogleAnalyticsCloseTitle('vip_v2_loading_close');
    return <Loading size="xl" />;
  }

  const goToAuthenticationPage = (!userEmail && showSignInForm && !isLoggedIn && !useMagicLink && !useValidationAccessCode);
  const goToEmailAuthenticationPage = ((isPopPath || isReviewPath) && !userEmail && !isLoggedIn);

  if (goDirectlyToSignup()) {
    setGoogleAnalyticsCloseTitle('v2_sign_up_close');
    return (
      <SignUpV2Form
        isBecomeVipModal={isBecomeVipModal}
        /* instanbul ignore next */
        onExistingUser={(email) => {
          if (!useValidationAccessCode && !isLoyaltyEnabled) {
            dispatch(setVipData({ userEmail: email }));
            setShowFollowerInfoPage(true);
          }
        }}
        /* instanbul ignore next */
        onExistingUserByPhone={(phone) => {
          if (!useValidationAccessCode && !isLoyaltyEnabled) {
            dispatch(setVipData({ userPhone: phone }));
            setShowFollowerInfoPage(true);
          }
        }}
        restaurant={restaurant}
        setFavoriteLocationId={setFavoriteLocationId}
        setNewUserName={setNewUserName}
        setNewUserPhone={setNewUserPhone}
        setUseAccessCode={setUseAccessCode}
        setUseValidationAccessCode={setUseValidationAccessCode}
        userData={userData}
        userEmail={userEmail}
        userPhone={userPhone}
      />
    );
  } else if (goToAuthenticationPage) {
    // When a user uses the sign in nav link
    setGoogleAnalyticsCloseTitle('vip_v2_authentication_page_close');
    return (
      <AuthenticationPage
        useMagicLink={() => setUseMagicLink(true)}
        setUserData={setUserData}
        setUseValidationAccessCode={setUseValidationAccessCode}
        setUsePhoneSignInPage={setUsePhoneSignInPage}
      />
    );
  } else if (goToEmailAuthenticationPage) {
    setGoogleAnalyticsCloseTitle(isPopPath ? 'v2_pop_path_authentication_page_close' : 'v2_review_path_authentication_page_close');

    return (
      <EmailAuthenticationForm />
    );
  } else if (goToOneLastStepPage(userData, useValidationAccessCode, isLoyaltyEnabled, newUserPhone, useDifferentNumber)) {
    return (
      <OneLastStep
        isLoggedIn={isLoggedIn}
        restaurantId={restaurant.id}
        setNewUserPhone={setNewUserPhone}
        setUseDifferentNumber={setUseDifferentNumber}
        userData={userData}
        userEmail={userEmail}
      />
    );
  } else if (goToValidationAccessCodePage(userData, useValidationAccessCode, isLoyaltyEnabled, newUserPhone)) {
    return (
      <AccessCodeValidationForm
        isLoggedIn={isLoggedIn}
        lastFour={userData?.lastFourDigitsPhoneNumber || newUserPhone?.slice(-4)}
        newUserPhone={newUserPhone}
        restaurantId={restaurant.id}
        setUseDifferentNumber={setUseDifferentNumber}
        setUseValidationAccessCode={setUseValidationAccessCode}
        userEmail={userEmail}
      />
    );
  } else if (goToNewFollowerPage(userData, showFollowerInfoPage, isLoggedIn, useValidationAccessCode)) {
    // When a user is has an account but is not a follower show new follower info page
    setGoogleAnalyticsCloseTitle('v2_already_vip_close');
    return (
      <NewFollowerInfoPage
        offerMessageCampaignCount={restaurant.offerMessageCampaignCount}
        restaurantName={restaurant.name}
        showSignInForm={() => {
          setShowFollowerInfoPage(false);
        }}
        showSignUpForm={() => {
          dispatch(setVipData());
          setShowFollowerInfoPage(false);
        }}
        userData={userData}
        userEmail={userEmail}
      />
    );
  } else if (goToSignUpSuccessPage(userData, isLoggedIn, userEmail, showSignInForm, isMagicLinkPath, useMagicLink)) {
    // When a user is new and uses the sign up link or prompt
    setGoogleAnalyticsCloseTitle('v2_success_close');
    return (
      <SignUpSuccessPage
        closeModal={closeModal}
        firstName={newUserName}
        goToProfileModal={goToProfileModal}
        restaurant={restaurant}
      />
    );
  } else if (goToSignUpFormPage(userData, isMagicLinkPath, userEmail, userPhone, showSignInForm)) {
    setGoogleAnalyticsCloseTitle('v2_sign_up_close');
    return (
      <SignUpV2Form
        isBecomeVipModal={isBecomeVipModal}
        onExistingUser={(email) => {
          if (!useValidationAccessCode && !isLoyaltyEnabled) {
            dispatch(setVipData({ userEmail: email }));
            setShowFollowerInfoPage(true);
          }
        }}
        onExistingUserByPhone={(phone) => {
          if (!useValidationAccessCode && !isLoyaltyEnabled) {
            dispatch(setVipData({ userPhone: phone }));
            setShowFollowerInfoPage(true);
          }
        }}
        restaurant={restaurant}
        setFavoriteLocationId={setFavoriteLocationId}
        setNewUserName={setNewUserName}
        setNewUserPhone={setNewUserPhone}
        setUseAccessCode={setUseAccessCode}
        setUseValidationAccessCode={setUseValidationAccessCode}
        userData={userData}
        userEmail={userEmail}
        userPhone={userPhone}
      />
    );
  } else if (goToSignInVerifiedPage(userData, isLoggedIn)) {
    // When user has an account, used for followers and non-followers
    setGoogleAnalyticsCloseTitle('vip_v2_verified_page_close');
    return (
      <SignInVerifiedPage
        closeModal={closeModal}
        goToFavoriteLocationModal={goToFavoriteLocationModal}
        goToProfileModal={goToProfileModal}
        restaurant={restaurant}
        usedAccessCode={useAccessCode}
        usedMagicLink={isMagicLinkPath}
        userOffersCount={userData?.orderingOfferCodesCount}
      />
    );
  } else if (goToUseAccessCodePage(userData, useAccessCode)) {
    setGoogleAnalyticsCloseTitle('v2_access_code_close');
    return (
      <AccessCodeSignInForm
        closeModal={closeModal}
        refetchUser={refetchUser}
        restaurantId={restaurant.id}
        source={followerSource}
        userData={userData}
        userEmail={userEmail}
        userPhone={userPhone}
      />
    );
  } else if (goToMagicLinkSentPage(userData, useMagicLink)) {
    setGoogleAnalyticsCloseTitle('v2_magic_link_close');
    return (
      <MagicLinkSentPage
        closeVipModal={closeModal}
        userEmail={userEmail}
      />
    );
  } else if (goToSignInPage(userData, isLoggedIn, usePhoneSignInPage)) {
    setGoogleAnalyticsCloseTitle('vip_v2_sign_in_form_close');
    return (
      <SignInV2Form
        favoriteLocationId={favoriteLocationId}
        followerSource={followerSource}
        refetchUser={refetchUser}
        requireValidation={() => setUseValidationAccessCode(true)}
        restaurantId={restaurant.id}
        restaurantName={restaurant.name}
        useAccessCode={() => setUseAccessCode(true)}
        userData={userData}
        userEmail={userEmail}
        useMagicLink={() => setUseMagicLink(true)}
      />
    );
  } else if (goToPhoneSignInPage(isLoggedIn, userData, usePhoneSignInPage)) {
    return (
      <PhoneSignInV2Form
        favoriteLocationId={favoriteLocationId}
        restaurantId={restaurant.id}
        restaurantName={restaurant.name}
        refetchUser={refetchUser}
        useAccessCode={() => setUseAccessCode(true)}
        requireValidation={() => setUseValidationAccessCode(true)}
        userData={userData}
        userPhone={userPhone}
        followerSource={followerSource}
      />
    );
  } else {
    return (
      <SignUpPhoneOrEmailForm
        restaurant={restaurant}
        userEmail={userEmail}
        userPhone={userPhone}
        setNewUserPhone={setNewUserPhone}
      />
    );
  }
};

VipV2Router.defaultProps = {
  newUserName: '',
  newUserPhone: '',
};

VipV2Router.propTypes = {
  closeModal: PropTypes.func.isRequired,
  favoriteLocationId: PropTypes.string.isRequired,
  followerSource: PropTypes.string.isRequired,
  goToFavoriteLocationModal: PropTypes.func.isRequired,
  goToProfileModal: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isLoyaltyEnabled: PropTypes.bool.isRequired,
  isMagicLinkPath: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  newUserName: PropTypes.string,
  newUserPhone: PropTypes.string,
  refetchUser: PropTypes.func.isRequired,
  restaurant: PropTypes.object.isRequired,
  setFavoriteLocationId: PropTypes.func.isRequired,
  setGoogleAnalyticsCloseTitle: PropTypes.func.isRequired,
  setNewUserName: PropTypes.func.isRequired,
  setNewUserPhone: PropTypes.func.isRequired,
  setShowFollowerInfoPage: PropTypes.func.isRequired,
  setUseAccessCode: PropTypes.func.isRequired,
  setUseDifferentNumber: PropTypes.func.isRequired,
  setUseMagicLink: PropTypes.func.isRequired,
  setUsePhoneSignInPage: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
  setUseValidationAccessCode: PropTypes.func.isRequired,
  showFollowerInfoPage: PropTypes.bool.isRequired,
  showSignInForm: PropTypes.bool.isRequired,
  useAccessCode: PropTypes.bool.isRequired,
  useDifferentNumber: PropTypes.bool.isRequired,
  useMagicLink: PropTypes.bool.isRequired,
  usePhoneSignInPage: PropTypes.bool.isRequired,
  userData: PropTypes.object.isRequired,
  userEmail: PropTypes.string.isRequired,
  userPhone: PropTypes.string.isRequired,
  useValidationAccessCode: PropTypes.bool.isRequired,
  vipData: PropTypes.object.isRequired,
};

export default VipV2Router;
