import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { compose, mapProps } from '@shakacode/recompose';
import { connect } from 'react-redux';
import { Button } from '@popmenu/admin-ui';
import { Grid, Icon, Typography } from '@popmenu/common-ui';
import { Mail } from '@popmenu/web-icons';

import { useMutation } from '~/lazy_apollo/client';
import { createEvent } from '~/utils/eventable';
import { formats } from '~/shared/sessions/utils';
import { useIntl } from 'react-intl';
import { withStyles } from '../../utils/withStyles';
import { useFeatureFlags } from '../../utils/featureFlagsContext';
import createUserMutation from '../../libs/gql/mutations/users/createUserMutation.gql';
import sendUserSmsAccessCodeMutation from '../../libs/gql/mutations/users/sendUserSmsAccessCodeMutation.gql';
import consumerUserByEmailQuery from '../../libs/gql/queries/users/consumerUserByEmailQuery.gql';
import consumerUserByPhoneQuery from '../../libs/gql/queries/users/consumerUserByPhoneQuery.gql';
import authenticationPageStyles from './styles';
import { setVipData } from '../ModalActions';
import { withCurrentSession } from '../CurrentSessionProvider';
import { withRestaurant } from '../../utils/withRestaurant';
import { withSnackbar } from '../../utils/withSnackbar';
import sendUserAutoLoginEmailMutation from '../../libs/gql/mutations/users/sendUserAutoLoginEmailMutation.gql';

import BasicForm from '../../admin/shared/forms/BasicForm';
import SocialButton from '../sessions/SocialButton';
import AuthenticationPageFields from './AuthenticationPageFields';

export const onCompleteCreateUser = (data, setUserData, setUseValidationAccessCode, isLoyaltyEnabled, sendUserSmsAccessCode, restaurantId) => {
  if (data?.createUser?.user) {
    const { isTextValidated, phone, email } = data.createUser.user;
    setUserData(data.createUser.user);
    if (!isTextValidated && isLoyaltyEnabled) {
      // if ncyloyaltyenabled and user.phone, send sms mutation
      setUseValidationAccessCode(true);
      if (phone) {
        sendUserSmsAccessCode({
          variables: {
            restaurantId,
            userEmail: email,
          },
        });
      }
    }
  }
};

const emailEntered = str => formats.email.test(str);
const phoneEntered = str => formats.phone.test(str);

const AuthenticationPage = ({ classes, restaurant, isLoggedIn, isSessionLoading, setUserData, setUseValidationAccessCode, showSnackbarError, setUsePhoneSignInPage, ...props }) => {
  const intl = useIntl();
  const [userInput, setUserInput] = useState('');
  const [sendUserAutoLoginEmail] = useMutation(sendUserAutoLoginEmailMutation);
  const [sendUserSmsAccessCode] = useMutation(sendUserSmsAccessCodeMutation);

  const { isFeatureActive } = useFeatureFlags();
  const isLoyaltyEnabled = isFeatureActive('isLoyaltyEnabled');
  const userLookupQuery = () => (emailEntered(userInput) ? consumerUserByEmailQuery : consumerUserByPhoneQuery);
  const lookupVariables = () => (emailEntered(userInput) ? { restaurantId: restaurant.id, userEmail: userInput || '' } : { restaurantId: restaurant.id, userPhone: userInput || '' });

  const handleUserLookupResponse = (data) => {
    const vipData = emailEntered(userInput) ? { userEmail: userInput } : { userPhone: userInput };
    if (data?.consumerUserByEmail) {
      props.setVipData(vipData);
      return;
    }
    if (data?.consumerUserByPhone) {
      setUsePhoneSignInPage(true);
      props.setVipData(vipData);
      return;
    }

    // this is the case where we don't find a user
    // we need to set the source to the signup
    props.setVipData({ ...vipData, isBecomeVipModal: true });
  };

  const onSendAccessToken = (magicLinkEmail) => {
    props.setVipData({ userEmail: magicLinkEmail });
    // send the magic link to the given email address
    sendUserAutoLoginEmail({
      variables: {
        email: magicLinkEmail,
        restaurantId: restaurant.id,
      },
    }).then(() => {
      props.useMagicLink();
    }).catch((err) => {
      showSnackbarError(err);
    });
  };
  if (isLoggedIn || isSessionLoading) {
    return null;
  }
  return (
    <Grid container spacing={2} align="center">
      <Grid item xs={12}>
        <Typography className={classes.header} id="become-vip-title">
          <FormattedMessage id="sessions.sign_in" defaultMessage={`Sign in to ${restaurant.name}`} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.subheader} id="become-vip-description">
          <FormattedMessage id="sessions.sign_up_v2_subheader" defaultMessage="A few more questions to help better communicate to you" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <BasicForm
          mutate={{
            fetchPolicy: 'network-only',
            mutation: userLookupQuery(),
            onCompleted: (data) => {
              if (emailEntered(userInput) || phoneEntered(userInput)) {
                handleUserLookupResponse(data);
              } else {
                showSnackbarError(intl.formatMessage({ defaultMessage: 'Invalid email or phone number', id: 'sessions.invalid_email_or_phone' }));
              }
            },
            onError: err => showSnackbarError(err),
            toVariables: lookupVariables,
          }}
        >
          {({ values }) => (
            <React.Fragment>
              <AuthenticationPageFields
                classes={classes}
                values={values}
                setUserInput={setUserInput}
              />
              <Grid item xs={12}>
                <Typography className={classes.dividerWrapper}>
                  <span className={classes.dividerText}>
                    <FormattedMessage id="sessions.separator" defaultMessage="or" />
                  </span>
                </Typography>
                <div>
                  <Button
                    className={classes.signInLinkButton}
                    disabled={!emailEntered(values.userInput)}
                    onClick={() => {
                      createEvent({
                        eventableType: 'BecomeVipV2',
                        eventType: 'v2_verify_magic_link_button',
                      });
                      onSendAccessToken(values.userInput);
                    }}
                    size="large"
                    textTransform="none"
                    variant="outlined"
                  >
                    <Icon icon={Mail} className={classes.buttonIcon} />
                    <FormattedMessage id="sessions.sign_in_magic_link" defaultMessage=" Email me a Magic Link" />
                  </Button>
                </div>
              </Grid>
            </React.Fragment>
          )}
        </BasicForm>
      </Grid>
      <Grid item xs={12}>
        <BasicForm
          labelPosition="top"
          mutate={{
            mutation: createUserMutation,
            onCompleted: (data) => {
              onCompleteCreateUser(data, setUserData, setUseValidationAccessCode, isLoyaltyEnabled, sendUserSmsAccessCode, restaurant.id);
            },
            toVariables: ({ facebookCode, googleCode, ...variables }) => ({
              facebookCode,
              googleCode,
              restaurantId: restaurant.id,
              userInput: { ...variables },
            }),
          }}
          onSubmit={({ facebookCode, googleCode }) => {
            if (facebookCode) {
              createEvent({
                eventableType: 'BecomeVipV2',
                eventType: 'v2_page_facebook_log_in',
              });
            } else if (googleCode) {
              createEvent({
                eventableType: 'BecomeVipV2',
                eventType: 'v2_page_google_log_in',
              });
            }
          }}
        >
          {({ submitManual }) => (
            <Grid container spacing={2}>
              {!isFeatureActive('facebook_oauth_disabled') && (
                <Grid item xs={12}>
                  <SocialButton
                    baseLocale="social_accounts.social_types.facebook_type"
                    buttonProps={{ textTransform: 'none' }}
                    socialType="facebook_type"
                    submit={(e, variables) => submitManual(variables)}
                    tokenName="facebook_type"
                  >
                    <FormattedMessage
                      data-testid="testing"
                      id="sessions.omniauth.facebook"
                      defaultMessage="Sign in with Facebook"
                    />
                  </SocialButton>
                </Grid>
              )}
              <Grid item xs={12}>
                <SocialButton
                  baseLocale="social_accounts.social_types.google_type"
                  buttonProps={{ textTransform: 'none' }}
                  socialType="google_type"
                  submit={(e, variables) => submitManual(variables)}
                  tokenName="google_type"
                >
                  <FormattedMessage
                    id="sessions.omniauth.google"
                    defaultMessage="Sign in with Google"
                  />
                </SocialButton>
              </Grid>
            </Grid>
          )}
        </BasicForm>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.footerText}>
          <span>
            <FormattedMessage id="sessions.no_account" defaultMessage="Don't have an account?" />
          </span>
          <Button
            data-cy="become-a-vip-popup"
            data-testid="become-a-vip-popup"
            variant="text"
            onClick={() => {
              createEvent({
                eventableType: 'BecomeVipV2',
                eventType: 'v2_become_vip_sign_in_page',
              });

              props.setVipData({ isBecomeVipModal: true });
            }}
          >
            <span className={classes.buttonLink}>
              <FormattedMessage id="consumer.ordering.vip_title" defaultMessage="Become a VIP" />
            </span>
          </Button>
        </Typography>
      </Grid>
    </Grid>
  );
};

AuthenticationPage.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isSessionLoading: PropTypes.bool.isRequired,
  restaurant: PropTypes.shape({
    customFollowPath: PropTypes.string,
    followButtonCta: PropTypes.string,
    followHeadingCta: PropTypes.string,
    followSubheadingCta: PropTypes.string,
  }).isRequired,
  setUsePhoneSignInPage: PropTypes.func.isRequired,
  setVipData: PropTypes.func.isRequired,
  showSnackbarError: PropTypes.func.isRequired,
  useMagicLink: PropTypes.func.isRequired,
};

export default compose(
  connect(() => ({}), {
    setVipData,
  }),
  withRestaurant,
  withStyles(authenticationPageStyles),
  withSnackbar,
  withCurrentSession,
  mapProps(({ currentSession, ...props }) => ({
    isLoggedIn: !!currentSession.user,
    isSessionLoading: !currentSession.id,
    ...props,
  })),
)(AuthenticationPage);
