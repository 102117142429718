import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '~/lazy_apollo/client';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography, Icon } from '@popmenu/common-ui';
import { createEvent } from '~/utils/eventable';
import { useSnackbar } from '~/utils/withSnackbar';
import { classNames } from '../../utils/withStyles';
import BasicModal from '../../admin/shared/BasicModal';
import consumerUserByEmailQuery from '../../libs/gql/queries/users/consumerUserByEmailQuery.gql';
import consumerUserByPhoneQuery from '../../libs/gql/queries/users/consumerUserByPhoneQuery.gql';
import PopmenuRed from '../../assets/svg/popmenu_icon_red.svg';

const VipV2ModalContent = ({
  classes,
  restaurant,
  userEmail,
  userPhone,
  userData,
  setUsePhoneSignInPage,
  setUseValidationAccessCode,
  displayForm,
  backgroundImageUrl,
  showVipV2Modal,
  closeModal,
  googleAnalyticsCloseTitle,
}) => {
  const userLookupQuery = userEmail ? consumerUserByEmailQuery : consumerUserByPhoneQuery;
  const lookupVariables = userEmail ? { restaurantId: restaurant.id, userEmail: userEmail || '' } : { restaurantId: restaurant.id, userPhone: userPhone || '' };
  const { showSnackbarError } = useSnackbar();

  const { data, loading } = useQuery(userLookupQuery, {
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      showSnackbarError(error);
    },
    skip: !userEmail && !userPhone,
    variables: lookupVariables,
  });

  useEffect(() => {
    if (data?.consumerUserByPhone) {
      setUsePhoneSignInPage(true);
    }

    if (data?.consumerUserByEmail) {
      if (userData && userData.isFollower === true) {
        setUseValidationAccessCode(true);
      } else {
        setUseValidationAccessCode(false);
      }
      setUsePhoneSignInPage(false);
    }
  }, [data, userData, setUsePhoneSignInPage, setUseValidationAccessCode]);

  return (
    <BasicModal
      className="pm-become-vip-v2-modal"
      closeModal={() => {
        createEvent({
          eventableType: 'BecomeVipV2',
          eventType: googleAnalyticsCloseTitle || 'vip_v2_modal_close',
        });
        closeModal();
      }}
      show={showVipV2Modal}
      size="md"
      PaperProps={{
        'aria-describedby': 'become-vip-description',
        'aria-labelledby': 'become-vip-title',
      }}
    >
      <Grid container alignItems="stretch" spacing={8}>
        <Grid
          className={classNames(classes.logoColumn, 'pm-vip-v2-modal-logo-container')}
          item
          lg={4}
          md={3}
          xs={12}
          style={{
            backgroundImage: backgroundImageUrl ? `url("${backgroundImageUrl}")` : 'none',
          }}
        >
          {restaurant.logoUrl && (
          <div className={classNames(restaurant.signUpSetting.signUpLogoEffect === 'sule_none' ? null :
            restaurant.signUpSetting.signUpLogoEffect === 'sule_lighten' ? classes.suleLighten :
              classes.suleDarken, 'pm-vip-v2-modal-restaurant-logo')}
          >
            <img
              alt={restaurant.name}
              className={classes.restaurantLogo}
              src={restaurant.logoUrl}
            />
          </div>
          )}
        </Grid>
        <Grid item lg={8} md={9} xs={12} className="pm-vip-sign-up-v2-form-container">
          {displayForm(data?.consumerUserByEmail || data?.consumerUserByPhone || userData, loading)}
          <div className={classes.footerContainer}>
            <Typography className={classes.footerText}>
              <FormattedMessage id="sessions.footer_text" defaultMessage="Powered By" />
            </Typography>
            <Icon icon={PopmenuRed} alt="popmenu" className={classes.popmenuLogo} aria-label="popmenu" />
          </div>
        </Grid>
      </Grid>
    </BasicModal>
  );
};

VipV2ModalContent.defaultProps = {
  userEmail: null,
  userPhone: null,
};

VipV2ModalContent.propTypes = {
  backgroundImageUrl: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  displayForm: PropTypes.func.isRequired,
  googleAnalyticsCloseTitle: PropTypes.string.isRequired,
  restaurant: PropTypes.object.isRequired,
  setUsePhoneSignInPage: PropTypes.func.isRequired,
  setUseValidationAccessCode: PropTypes.func.isRequired,
  showVipV2Modal: PropTypes.bool.isRequired,
  userData: PropTypes.object.isRequired,
  userEmail: PropTypes.string,
  userPhone: PropTypes.string,
};

export default VipV2ModalContent;
