import React from 'react';
import { TextFieldGroup } from '~/admin/shared/forms/BasicForm';
import { notBlankValidator } from '~/utils/forms';
import { useIntl } from 'react-intl';
import { useField } from 'react-form';
import { formats } from '~/shared/sessions/utils';

const PhoneFormTextFields = ({
  t,
  signUpSetting,
  setPhone,
  setEmail,
}) => {
  const intl = useIntl();

  useField('phone', {
    validate: (value) => {
      if (!value) {
        return intl.formatMessage({ defaultMessage: 'is required', id: 'users.phone.error.required' });
      }

      if (!formats.phone.test(value)) {
        return intl.formatMessage({ defaultMessage: 'is invalid', id: 'users.phone.error.phoneFormat' });
      }
      return false;
    },
  });

  useField('email', {
    validate: (value) => {
      if (!value) return false;

      if (!formats.email.test(value)) {
        return intl.formatMessage({ defaultMessage: 'is invalid', id: 'users.email.error.emailFormat' });
      }
      return false;
    },
  });

  return (
    <React.Fragment>
      <TextFieldGroup
        id="pm-signup-phone-input"
        field="phone"
        title={t('users.phone')}
        type="tel"
        variant="outlined"
        onInput={e => setPhone(e.target.value)}
      />
      <hr />
      <TextFieldGroup
        id="pm-signup-email-input"
        inputProps={{ 'aria-required': 'true' }}
        field="email"
        title={t('users.email_optional')}
        variant="outlined"
        onInput={e => setEmail(e.target.value)}
      />
      {signUpSetting.isSignUpNameEnabled && (
      <TextFieldGroup
        id="pm-signup-name-input"
        inputProps={{ 'aria-required': 'true' }}
        field="name"
        title={t('models.user.full_name')}
        validate={signUpSetting.isSignUpNameRequired ? notBlankValidator : undefined}
        variant="outlined"
      />
      )}
    </React.Fragment>
  );
};

export default PhoneFormTextFields;
