import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { SelectGroup } from '../../../admin/shared/forms/BasicForm';
import { validateMonthDate, monthDayOptions } from '../utils';

const BirthdayFields = ({
  t,
  classes,
  signUpSetting,
  values,
  basicFormApi,
  setIsMonthDropdownOpen,
  setIsDayDropdownOpen,
}) => {
  if (!signUpSetting.isSignUpBirthdayEnabled) return null;

  return (
    <Grid container spacing={2}>
      <fieldset className={classes.fieldsetWrapper}>
        <Grid item xs={12} className={classes.dateFieldTitleWrapper}>
          <legend>
            <Typography className={classes.dateFieldTitle}>
              {signUpSetting.isSignUpBirthdayRequired ? t('users.birthday') : t('users.birthday_optional')}
            </Typography>
          </legend>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <SelectGroup
              onSelect={e => e.preventDefault()}
              aria-label={t('models.user.month')}
              data-cy={'birthMonth'}
              isClearable={!signUpSetting.isSignUpBirthdayRequired}
              field="birthMonth"
              options={monthDayOptions(t, values, 'birth').months}
              title={t('models.user.month')}
              validate={validateMonthDate(signUpSetting.isSignUpBirthdayRequired, 'birthMonth', basicFormApi)}
              size="lg"
              onMenuOpen={() => setIsMonthDropdownOpen(true)}
              onMenuClose={() => setIsMonthDropdownOpen(false)}
            />
          </Grid>
          <Grid item xs={5}>
            <SelectGroup
              aria-label={t('models.user.day')}
              data-cy={'birthDay'}
              isClearable={!signUpSetting.isSignUpBirthdayRequired}
              field="birthDay"
              options={monthDayOptions(t, values, 'birth').days}
              title={t('models.user.day')}
              validate={validateMonthDate(signUpSetting.isSignUpBirthdayRequired, 'birthDay', basicFormApi)}
              size="lg"
              onMenuOpen={() => setIsDayDropdownOpen(true)}
              onMenuClose={() => setIsDayDropdownOpen(false)}
            />
          </Grid>
        </Grid>
      </fieldset>
    </Grid>
  );
};

export default BirthdayFields;
