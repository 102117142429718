import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Box, Grid, Icon, Typography } from '@popmenu/common-ui';
import { AH, AHLevelProvider } from '~/consumer/shared/AccessibleHeading';
import { setVipData } from '~/shared/ModalActions';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import BasicForm, { SubmitGroup } from '~/admin/shared/forms/BasicForm';
import createUserMutation from '~/libs/gql/mutations/users/createUserMutation.gql';
import { useCurrentSession } from '~/shared/CurrentSessionProvider';
import SocialButton from '~/shared/sessions/SocialButton';
import { createEvent } from '~/utils/eventable';
import { useFeatureFlags } from '~/utils/featureFlagsContext';
import { formats } from '~/shared/sessions/utils';
import { useSnackbar } from '~/utils/withSnackbar';
import SignUpFormStyles from '../VipV2Modal/styles';
import OfferTag from '../../assets/svg/popmenu_guest_profile_offer_tag.svg';
import VIPSVG from '../../assets/svg/vip2.svg';
import { makeStyles, classNames } from '../../utils/withStyles';
import SignUpPhoneOrEmailFormFields from './SignUpPhoneOrEmailFormFields';

const useStyles = makeStyles(SignUpFormStyles);

/* eslint-disable max-lines-per-function */
const SignUpPhoneOrEmailForm = ({
  restaurant,
  userEmail,
  userPhone,
  userData,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isOnOloPage = useSelector(state => state.modals.isOnOloPage);
  const directedFromPrompt = !!userEmail || !!userPhone;
  const [useThirdPartyAuth, setUseThirdPartyAuth] = useState(false);
  const t = useIntl();
  const currentSession = useCurrentSession();
  const isLoggedIn = !!currentSession.user;
  const { user } = currentSession;
  const { showSnackbarError } = useSnackbar();
  const heading =
    restaurant.signUpHeadingCta ||
    (directedFromPrompt ?
      t('sessions.sign_up_v2_header') :
      t('sessions.become_a_vip_header'));
  const subheading =
    restaurant.signUpSubheadingCta ||
    (directedFromPrompt ?
      t('sessions.sign_up_v2_subheader') :
      t('sessions.become_a_vip_subheader'));
  const { isFeatureActive } = useFeatureFlags();
  const isFacebookOauthDisabled = isFeatureActive('facebook_oauth_disabled');

  const emailEntered = str => formats.email.test(str);

  const phoneEntered = str => formats.phone.test(str);

  const { offerMessageCampaignCount } = restaurant;

  useEffect(() => {
    if (isLoggedIn && useThirdPartyAuth) {
      setUseThirdPartyAuth(false);
      dispatch(setVipData({ userEmail: user.email }));
    }
  }, [dispatch, isLoggedIn, useThirdPartyAuth, user]);

  return (
    <div className={classes.verifiedPageContainer}>
      {isOnOloPage && userData?.offerMessageCampaignCount > 0 ? (
        <React.Fragment>
          <OfferTag width={60} height="100%" />
          <AHLevelProvider>
            <AH
              typography
              className={classes.headerTertiary}
              variant="h3"
              align="center"
              id="become-vip-title"
              aria-live="polite"
              role="status"
            >
              <FormattedMessage
                id="sessions.with_offers.sign_up"
                defaultMessage="You have {offerMessageCampaignCount} available {offer}!"
                values={{
                  offer: offerMessageCampaignCount === 1 ? 'offer' : 'offers',
                  offerMessageCampaignCount,
                }}
              />
            </AH>
          </AHLevelProvider>
          <AHLevelProvider>
            <AH
              typography
              align="center"
              className={classes.subheader}
              variant="h5"
              id="become-vip-description"
              aria-live="polite"
              role="status"
            >
              <FormattedMessage
                id="sessions.with_offers.sign_up_v2_subtitle"
                defaultMessage="Sign up to Become a VIP and apply {offer}"
                values={{
                  offer:
                    offerMessageCampaignCount === 1 ?
                      'this offer to your order' :
                      'one of them to your order',
                }}
              />
            </AH>
          </AHLevelProvider>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Icon icon={VIPSVG} aria-label={'VIP Icon'} className={classes.signUpIcon} />
          <AH
            typography
            className={classes.header}
            component="h3"
            variant="h4"
            align="center"
            id="become-vip-title"
            aria-live="polite"
            role="status"
          >
            {heading}
          </AH>
          <br />
          <Typography
            className={classes.subheader}
            align="center"
            id="become-vip-description"
            aria-live="polite"
            role="status"
          >
            {subheading}
          </Typography>
        </React.Fragment>
      )}

      <AHLevelProvider>
        <Grid item xs={12} className={classes.emailFormContainer}>
          <BasicForm
            onSubmit={({ userInput }) => {
              if (emailEntered(userInput)) {
                dispatch(setVipData({ userEmail: userInput }));
              } else if (phoneEntered(userInput)) {
                dispatch(setVipData({ userPhone: userInput }));
              } else {
                showSnackbarError(t.formatMessage({ defaultMessage: 'Invalid email or phone number', id: 'sessions.invalid_email_or_phone' }));
              }
            }}
            style={{ display: 'grid', width: '100%' }}
          >
            {({ values }) => (
              <React.Fragment>
                <SignUpPhoneOrEmailFormFields />
                <SubmitGroup
                  className={classes.buttonContainer}
                  ButtonProps={{
                    'aria-label': 'Join for Free',
                    className: classes.button,
                    'data-cy': 'join-for-free-button',
                  }}
                  inline
                  title={(
                    <span className={classes.buttonText}>
                      <FormattedMessage id="follow.join_button" defaultMessage="Join for free" />
                    </span>
                    )}
                  disabled={!values.userInput}
                />
              </React.Fragment>
            )}
          </BasicForm>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.divider}>
            <span className={classes.dividerText}>
              <FormattedMessage id="follow.prompt.divider_text" defaultMessage="or join with" />
            </span>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.socialButtonWrapper}>
          <BasicForm
            labelPosition="top"
            mutate={{
              mutation: createUserMutation,
              toVariables: ({ facebookCode, googleCode }) => ({
                facebookCode,
                googleCode,
              }),
            }}
          >
            {({ submitManual }) => (
              <Grid container spacing={2}>
                {!isFacebookOauthDisabled && (
                  <Grid item xs={6}>
                    <SocialButton
                      aria-label="Sign up to our VIP program using Facebook (opens in a new window)"
                      baseLocale="social_accounts.social_types.facebook_type"
                      buttonProps={{ className: classNames(classes.facebookButton, classes.socialButton), variant: 'outlined' }}
                      size="medium"
                      socialType="facebook_type"
                      submit={(e, variables) => {
                        if (!restaurant.customFollowPath) {
                          createEvent({
                            eventableType: 'BecomeVipV2',
                            eventType: 'v2_pop_up_join_facebook',
                          });
                          setUseThirdPartyAuth(!!variables.facebookCode);
                          submitManual(variables);
                        }
                      }}
                      tokenName="facebook_type"
                      customFollowPath={restaurant.customFollowPath}
                    >
                      <FormattedMessage
                        id="sessions.omniauth.facebook"
                        defaultMessage="Facebook"
                      />
                    </SocialButton>
                  </Grid>
                )}
                <Grid item xs={isFacebookOauthDisabled ? 12 : 6}>
                  <SocialButton
                    aria-label="Sign up to our VIP program using Google (opens in a new window)"
                    baseLocale="social_accounts.social_types.google_type"
                    buttonProps={{ className: classes.socialButton, variant: 'outlined' }}
                    size="medium"
                    socialType="google_type"
                    submit={(e, variables) => {
                      if (!restaurant.customFollowPath) {
                        createEvent({
                          eventableType: 'BecomeVipV2',
                          eventType: 'v2_pop_up_join_google',
                        });
                        setUseThirdPartyAuth(!!variables.googleCode);
                        submitManual(variables);
                      }
                    }}
                    tokenName="google_type"
                    customFollowPath={restaurant.customFollowPath}
                  >
                    <FormattedMessage
                      id="sessions.omniauth.google"
                      defaultMessage="Google"
                    />
                  </SocialButton>
                </Grid>
              </Grid>
            )}
          </BasicForm>
        </Grid>
      </AHLevelProvider>
    </div>
  );
};
/* eslint-enable max-lines-per-function */

SignUpPhoneOrEmailForm.defaultProps = {
  userData: null,
  userEmail: null,
  userPhone: null,
};

SignUpPhoneOrEmailForm.propTypes = {
  restaurant: PropTypes.shape({
    signUpHeadingCta: PropTypes.string,
    signUpSubheadingCta: PropTypes.string,
  }).isRequired,
  userData: PropTypes.object,
  userEmail: PropTypes.string,
  userPhone: PropTypes.string,
};

export default SignUpPhoneOrEmailForm;
