import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { connect, useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Icon, Typography } from '@popmenu/common-ui';

import { createEvent } from '~/utils/eventable';
import { sortByKey } from '../../utils/arrays';
import { trackFacebookEvent } from '../../utils/fbq';
import { appendConversionScripts } from '../../utils/conversion';
import { notBlankValidator } from '../../utils/forms';
import { openTermsModal } from '../ModalActions';
import { withIntl } from '../../utils/withIntl';
import { withStyles } from '../../utils/withStyles';
import AnniversaryFields from './SignUp/AnniversaryFields';
import BirthdayFields from './SignUp/BirthdayFields';
import EmailFormTextFields from './SignUp/EmailFormTextFields';
import PhoneFormTextFields from './SignUp/PhoneFormTextFields';
import SignUpFormStyles from '../VipV2Modal/styles';
import createUserMutation from '../../libs/gql/mutations/users/createUserMutation.gql';
import createConsumerUserByPhoneMutation from '../../libs/gql/mutations/users/createConsumerUserByPhoneMutation.gql';
import createConsumerUserMutation from '../../libs/gql/mutations/users/createConsumerUserMutation.gql';
import restaurantLocationsQuery from '../../libs/gql/queries/locations/restaurantWithLocationNamesQuery.gql';

import Query from '../Query';
import BasicForm, { SelectGroup, SubmitGroup } from '../../admin/shared/forms/BasicForm';
import FollowDisclaimer from './FollowDisclaimer';
import MarketingOptInRequest from './MarketingOptInRequest';
import OfferTag from '../../assets/svg/popmenu_guest_profile_offer_tag.svg';
import VIPSVG from '../../assets/svg/vip2.svg';
import Loading from '../Loading';
import { getUserEntryPoint, validateSubmit } from './utils';
import { AH, AHLevelProvider } from '../../consumer/shared/AccessibleHeading';
import { useFeatureFlags } from '../../utils/featureFlagsContext';
import { setVipData } from '../ModalActions';
import { TermsModalType } from '../../utils/types';
import { updateAriaAttributes } from './accessibilityUtils';

const SignUpV2Form = (props) => {
  const { classes, restaurant, setFavoriteLocationId, setNewUserName, setNewUserPhone, setUseValidationAccessCode, t, onExistingUser, userEmail, userPhone, isBecomeVipModal, onExistingUserByPhone } = props;
  const { isFeatureActive } = useFeatureFlags();
  const dispatch = useDispatch();
  const isEmailOptInEnabled = isFeatureActive('isEmailOptInEnabled');
  const isSmsOptInEnabled = isFeatureActive('isSmsOptInEnabled');
  const isLoyaltyEnabled = isFeatureActive('isLoyaltyEnabled');
  const [isMonthDropdownOpen, setIsMonthDropdownOpen] = useState(false);
  const [isDayDropdownOpen, setIsDayDropdownOpen] = useState(false);

  const [email, setEmail] = useState(userEmail);
  const [phone, setPhone] = useState(userPhone);
  const { customConversionScripts, googleAdsConversionActionScripts, id, isMarketingOptInEnabled, offerMessageCampaignCount, signUpButtonCta, signUpHeadingCta, signUpSetting, signUpSubheadingCta } = restaurant;

  const signUpMutation = () => {
    if (isLoyaltyEnabled) {
      return createConsumerUserMutation;
    } else if (userEmail) {
      return createUserMutation;
    } else if (userPhone) {
      return createConsumerUserByPhoneMutation;
    }

    return createUserMutation;
  };

  const handleOnCompleted = (values) => {
    if (userEmail || !values.createConsumerUserByPhone?.isTextValidated) {
      if ((values.createUser && values.createUser.user) || (values.createConsumerUser && values.createConsumerUser.id)) {
        appendConversionScripts({ customScripts: customConversionScripts, googleAdsConversionActionScripts });
        trackFacebookEvent('CompleteRegistration');
        setNewUserName(values.createUser?.user?.firstName || values.createConsumerUser?.displayName);
        setUseValidationAccessCode(true);
        if (values.createConsumerUser) {
          dispatch(setVipData({ userEmail: email }));
        }
      } else {
        onExistingUser(email);
      }
    } else if (userPhone) {
      if (values.createConsumerUserByPhone && values.createConsumerUserByPhone.id) {
        appendConversionScripts({
          customScripts: values.customConversionScripts,
          googleAdsConversionActionScripts: values.googleAdsConversionActionScripts,
        });
        trackFacebookEvent('CompleteRegistration');
        setNewUserName(values.createConsumerUserByPhone?.displayName);
        setNewUserPhone(phone);
        setUseValidationAccessCode(true);

        if (values.createConsumerUserByPhone) {
          dispatch(setVipData({ userPhone: phone }));
        }
      } else {
        onExistingUserByPhone(phone);
      }
    }
  };

  const handleValidateSubmit = (values, enabledLocations) => {
    if (userEmail) {
      return validateSubmit(values, signUpSetting, enabledLocations.length, isLoyaltyEnabled);
    }

    return validateSubmit(values, signUpSetting, enabledLocations.length, isLoyaltyEnabled, false);
  };

  const createConsumerUserToVariables = ({ subscribeSms, subscribeEmail, ...variables }) => {
    if (userEmail) {
      const nameArray = variables.name && variables.name.split(' ');
      const userInputObj = {
        ...variables,
        anniversaryDay: variables.anniversaryDay ? parseInt(variables.anniversaryDay, 10) : undefined,
        anniversaryMonth: variables.anniversaryDay ? parseInt(variables.anniversaryMonth, 10) : undefined,
        birthDay: variables.birthDay ? parseInt(variables.birthDay, 10) : undefined,
        birthMonth: variables.birthDay ? parseInt(variables.birthMonth, 10) : undefined,
        firstName: nameArray ? (nameArray.length >= 2 ? nameArray.slice(0, nameArray.length - 1).join(' ') : variables.name) : undefined,
        lastName: variables.name ? (nameArray.length >= 2 ? nameArray[nameArray.length - 1] : '') : undefined,
        name: undefined,
        restaurantId: id,
        skipValidation: true,
        userEntryPoint: getUserEntryPoint(isBecomeVipModal),
        weddingDay: variables.weddingDay ? parseInt(variables.weddingDay, 10) : undefined,
        weddingMonth: variables.weddingMonth ? parseInt(variables.weddingMonth, 10) : undefined,
      };

      if (isEmailOptInEnabled) {
        userInputObj.isUnsubscribed = !subscribeEmail;
      } else {
        userInputObj.isUnsubscribed = false;
      }

      if (isSmsOptInEnabled) {
        userInputObj.isSmsUnsubscribed = !subscribeSms;
      } else {
        userInputObj.isSmsUnsubscribed = false;
      }

      return ({
        userInput: userInputObj,
      });
    }

    const nameArray = variables.name && variables.name.split(' ');

    const userInputObj = {
      ...variables,
      anniversaryDay: variables.anniversaryDay ? parseInt(variables.anniversaryDay, 10) : undefined,
      anniversaryMonth: variables.anniversaryDay ? parseInt(variables.anniversaryMonth, 10) : undefined,
      birthDay: variables.birthDay ? parseInt(variables.birthDay, 10) : undefined,
      birthMonth: variables.birthDay ? parseInt(variables.birthMonth, 10) : undefined,
      firstName: nameArray ?
        nameArray.length >= 2 ?
          nameArray.slice(0, nameArray.length - 1).join(' ') :
          variables.name :
        undefined,
      lastName: variables.name ?
        nameArray.length >= 2 ?
          nameArray[nameArray.length - 1] :
          '' :
        undefined,
      name: undefined,
      restaurantId: id,
      skipValidation: true,
      userEntryPoint: getUserEntryPoint(isBecomeVipModal),
      weddingDay: variables.weddingDay ? parseInt(variables.weddingDay, 10) : undefined,
      weddingMonth: variables.weddingMonth ? parseInt(variables.weddingMonth, 10) : undefined,
    };

    if (isEmailOptInEnabled) {
      userInputObj.isUnsubscribed = !subscribeEmail;
    } else {
      userInputObj.isUnsubscribed = false;
    }

    if (isSmsOptInEnabled) {
      userInputObj.isSmsUnsubscribed = !subscribeSms;
    } else {
      userInputObj.isSmsUnsubscribed = false;
    }

    return {
      input: {
        userInput: userInputObj,
      },
    };
  };

  const isOnOloPage = useSelector(state => state.modals.isOnOloPage);
  // did the user submit their email through the become a vip follow prompt (FollowPromptV2.jsx)
  const didSubmitEmailWithFollowPrompt = useSelector(state => state.modals.didSubmitEmailWithFollowPrompt);

  const formFieldsSetup = (data) => {
    if (userEmail) {
      return (
        <EmailFormTextFields
          t={t}
          signUpSetting={signUpSetting}
          isLoyaltyEnabled={isLoyaltyEnabled}
          isSmsOptInEnabled={isSmsOptInEnabled}
          setPhone={setPhone}
          setEmail={setEmail}
          userData={data}
        />
      );
    }

    return (
      <PhoneFormTextFields
        t={t}
        signUpSetting={signUpSetting}
        isLoyaltyEnabled={isLoyaltyEnabled}
        isSmsOptInEnabled={isSmsOptInEnabled}
        setPhone={setPhone}
        setEmail={setEmail}
      />
    );
  };

  // this useEffect is strictly for accessibility updates
  useEffect(() => {
    updateAriaAttributes(t('models.user.month'), isMonthDropdownOpen);
  }, [isMonthDropdownOpen, t]);

  useEffect(() => {
    updateAriaAttributes(t('models.user.day'), isDayDropdownOpen);
  }, [isDayDropdownOpen, t]);

  useEffect(() => {
    if (isOnOloPage && offerMessageCampaignCount > 0) {
      createEvent({
        eventableType: 'GuestProfile',
        eventLabel: 'offer_check | not_follower | offer | sign_in_modal',
        eventType: 'not_follower_profile_sign_in_offer_modal',
      });
    } else if (isOnOloPage) {
      createEvent({
        eventableType: 'GuestProfile',
        eventLabel: 'offer_check | not_follower | no_offer | sign_in_modal',
        eventType: 'not_follower_profile_sign_in_no_offer_modal',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Query
      query={restaurantLocationsQuery}
      variables={{
        restaurantId: id,
      }}
    >
      {({ data, loading }) => {
        if (loading || !data || !data.restaurant.locations) {
          return <Loading size="lg" />;
        }

        return (
          <BasicForm
            defaultValues={{
              anniversaryMonth: null,
              birthMonth: null,
              email: userEmail,
              phone: userPhone,
            }}
            mutate={{
              mutation: signUpMutation(),
              onCompleted: values => handleOnCompleted(values),
              toVariables: ({ subscribeSms, subscribeEmail, ...variables }) => {
                const input = createConsumerUserToVariables({ subscribeEmail, subscribeSms, ...variables });
                return input;
              },
            }}
            onSubmit={(values) => {
              createEvent({
                eventableType: 'BecomeVipV2',
                eventType: 'v2_sign_up_button',
              });
              setFavoriteLocationId(values.favoriteLocationId);
            }}
            data-cy="sign_up_v2_form"
          >
            {(basicFormApi) => {
              const directedFromPrompt = !!userEmail;
              const heading = signUpHeadingCta || (directedFromPrompt ? t('sessions.sign_up_v2_header') : t('sessions.become_a_vip_header'));
              const subheading = signUpSubheadingCta || (directedFromPrompt ? t('sessions.sign_up_v2_subheader') : t('sessions.become_a_vip_subheader'));
              const enabledLocations = data.restaurant.locations.filter(location => location.isLocationEnabled);
              const { values } = basicFormApi;

              return (
                <div className={classes.verifiedPageContainer}>
                  {(isOnOloPage || (didSubmitEmailWithFollowPrompt && restaurant.activeBecomeVipMarketingOffer)) && offerMessageCampaignCount > 0 ? (
                    <React.Fragment>
                      <OfferTag width={60} height="100%" />
                      <AH typography className={classes.headerTertiary} variant="h3" align="center" id="become-vip-title" aria-live="polite" role="status">
                        <FormattedMessage
                          id="sessions.with_offers.sign_up"
                          defaultMessage="You have {offerMessageCampaignCount} available {offer}!"
                          values={{ offer: offerMessageCampaignCount === 1 ? 'offer' : 'offers', offerMessageCampaignCount }}
                        />
                      </AH>
                      <AHLevelProvider>
                        <AH
                          typography
                          align="center"
                          className={classes.subheader}
                          variant="h5"
                          id="become-vip-description"
                          aria-live="polite"
                          role="status"
                        >
                          <FormattedMessage
                            id="sessions.with_offers.sign_up_v2_subtitle"
                            defaultMessage="Sign up to Become a VIP and apply {offer}"
                            values={{ offer: offerMessageCampaignCount === 1 ? 'this offer to your order' : 'one of them to your order' }}
                          />
                        </AH>
                      </AHLevelProvider>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Icon icon={VIPSVG} className={classes.signUpIcon} />
                      <AH typography className={classes.header} component="h3" variant="h4" align="center" id="become-vip-title" aria-live="polite" role="status">
                        {heading}
                      </AH>
                      <br />
                      <Typography className={classes.subheader} align="center" id="become-vip-description" aria-live="polite" role="status">
                        {subheading}
                      </Typography>
                    </React.Fragment>
                  )}
                  <AHLevelProvider>
                    {formFieldsSetup(data)}

                    <BirthdayFields
                      t={t}
                      classes={classes}
                      signUpSetting={signUpSetting}
                      values={values}
                      basicFormApi={basicFormApi}
                      setIsMonthDropdownOpen={setIsMonthDropdownOpen}
                      setIsDayDropdownOpen={setIsDayDropdownOpen}
                    />
                    <AnniversaryFields
                      t={t}
                      classes={classes}
                      signUpSetting={signUpSetting}
                      values={values}
                      basicFormApi={basicFormApi}
                    />
                    {enabledLocations.length > 1 && (
                      <Box marginTop={3}>
                        <SelectGroup
                          data-cy={'location'}
                          field="favoriteLocationId"
                          options={sortByKey(enabledLocations.map(option => ({
                            label: option.name,
                            value: option.id,
                          })), 'label')}
                          title={t('models.follower.favorite_location.name.required')}
                          validate={notBlankValidator}
                          onMenuOpen={() => {
                            const fieldName = 'favoriteLocationId';
                            const { setFieldMeta } = basicFormApi;
                            const value = values[fieldName];
                            if (!value) {
                              setFieldMeta(fieldName, { error: 'Favorite Location is a required field' });
                            }
                          }}
                        />
                      </Box>
                    )}
                    {/* NCR loyalty require phone number, so we must ask for marketing text opt-ins */}
                    {(isMarketingOptInEnabled || isLoyaltyEnabled) && (
                      <MarketingOptInRequest
                        grayedText
                        isLoyaltyEnabled={isLoyaltyEnabled}
                        smsOptInDisabled={!values.phone}
                      />
                    )}

                    <FollowDisclaimer
                      grayedText
                    />

                    <SubmitGroup
                      block
                      className={classes.pmSignUpV2Btn}
                      ButtonProps={{ className: 'gtm-sign-up-button', 'data-cy': 'sign-up-button' }}
                      color="primary"
                      disabled={handleValidateSubmit(values, signUpSetting, enabledLocations, isLoyaltyEnabled)}
                      size="lg"
                      title={signUpButtonCta}
                    />
                    <Button
                      className={classes.termsButton}
                      onClick={() => props.openTermsModal(TermsModalType.ClientTerms)}
                      variant="text"
                    >
                      <FormattedMessage className={classes.grayedText} id="follower.terms_of_service" defaultMessage="Popmenu Terms of Service" />
                    </Button>
                  </AHLevelProvider>
                </div>
              );
            }}
          </BasicForm>
        );
      }}
    </Query>
  );
};

SignUpV2Form.defaultProps = {
  userEmail: null,
  userPhone: null,
};

SignUpV2Form.propTypes = {
  classes: PropTypes.object.isRequired,
  onExistingUser: PropTypes.func.isRequired,
  onExistingUserByPhone: PropTypes.func.isRequired,
  restaurant: PropTypes.shape({
    customConversionScripts: PropTypes.arrayOf(PropTypes.shape({
      content: PropTypes.string,
      scriptType: PropTypes.string,
    })),
    googleAdsConversionActionScripts: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      tag: PropTypes.string,
    })),
    id: PropTypes.number,
    locations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
    logoUrl: PropTypes.string,
    name: PropTypes.string,
    signUpBackgroundImageUrl: PropTypes.string,
    signUpButtonCta: PropTypes.string,
    signUpHeadingCta: PropTypes.string,
    signUpSubheadingCta: PropTypes.string,
  }).isRequired,
  setFavoriteLocationId: PropTypes.func.isRequired,
  setNewUserName: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  userEmail: PropTypes.string,
  userPhone: PropTypes.string,
};

export default compose(
  connect(
    () => ({}),
    {
      openTermsModal,
    },
  ),
  withStyles(SignUpFormStyles),
  withIntl,
)(SignUpV2Form);
