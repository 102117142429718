import React, { useEffect } from 'react';
import { useQuery } from '~/lazy_apollo/client';
import PropTypes from 'prop-types';
import { Box, Drawer, Icon, Typography } from '@popmenu/common-ui';
import { X } from '@popmenu/web-icons';
import IconButton from '@material-ui/core/IconButton';
import { FormattedMessage } from 'react-intl';

import PopmenuRed from '../../assets/svg/popmenu_icon_red.svg';
import { executeWithProgressBar } from '../../utils/postponed';
import consumerUserByEmailQuery from '../../libs/gql/queries/users/consumerUserByEmailQuery.gql';
import consumerUserByPhoneQuery from '../../libs/gql/queries/users/consumerUserByPhoneQuery.gql';

const VipV2MobileDrawer = ({
  classes,
  closeModal,
  closeUserEmailCheckPrompt,
  displayForm,
  restaurant,
  setUseValidationAccessCode,
  setUsePhoneSignInPage,
  userData,
  userEmail,
  userPhone,
}) => {
  const userLookupQuery = userEmail ? consumerUserByEmailQuery : consumerUserByPhoneQuery;
  const lookupVariables = userEmail ? { restaurantId: restaurant.id, userEmail: userEmail || '' } : { restaurantId: restaurant.id, userPhone: userPhone || '' };

  const { data, loading } = useQuery(userLookupQuery, {
    fetchPolicy: 'cache-and-network',
    skip: !userEmail && !userPhone,
    variables: lookupVariables,
  });

  useEffect(() => {
    if (data?.consumerUserByPhone) {
      setUsePhoneSignInPage(true);
    }

    if (data?.consumerUserByEmail) {
      if (userData && userData.isFollower && userData.isFollower === true) {
        setUseValidationAccessCode(true);
      } else {
        setUseValidationAccessCode(false);
      }
      setUsePhoneSignInPage(false);
    }
  }, [data, userData, setUsePhoneSignInPage, setUseValidationAccessCode]);

  return (
    <Drawer
      anchor="right"
      open
      classes={{
        paper: classes.drawer,
      }}
    >
      <Box
        marginX={1.5}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <Box>
          <Box textAlign="right" marginTop={1}>
            <IconButton
              aria-label="close_vip_v2_drawer"
              onClick={() => executeWithProgressBar(() => {
                closeUserEmailCheckPrompt();
                closeModal();
              })}
              icon="cancel"
              size="small"
            >
              <Icon icon={X} size="extra-large" />
            </IconButton>
          </Box>
          {displayForm(data?.consumerUserByEmail || data?.consumerUserByPhone || userData, loading)}
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          paddingY={1.5}
        >
          <Typography className={classes.footerText}>
            <FormattedMessage id="sessions.footer_text" defaultMessage="Powered By" />
          </Typography>
          <Icon icon={PopmenuRed} alt="popmenu" className={classes.popmenuLogo} aria-label="popmenu" />
        </Box>
      </Box>
    </Drawer>
  );
};

VipV2MobileDrawer.defaultProps = {
  userEmail: null,
  userPhone: null,
};

VipV2MobileDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  closeUserEmailCheckPrompt: PropTypes.func.isRequired,
  displayForm: PropTypes.func.isRequired,
  restaurant: PropTypes.object.isRequired,
  setUsePhoneSignInPage: PropTypes.func.isRequired,
  setUseValidationAccessCode: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  userEmail: PropTypes.string,
  userPhone: PropTypes.string,
};

export default VipV2MobileDrawer;
