import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { SelectGroup } from '../../../admin/shared/forms/BasicForm';
import { validateMonthDate, monthDayOptions } from '../utils';

const AnniversaryFields = ({
  t,
  classes,
  signUpSetting,
  values,
  basicFormApi,
}) => {
  if (!signUpSetting.isSignUpAnniversaryEnabled) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.dateFieldTitleWrapper}>
        <Box mt={1}>
          <Typography className={classes.dateFieldTitle}>
            {signUpSetting.isSignUpAnniversaryRequired ?
              t('users.anniversary') :
              t('users.anniversary_optional')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={7}>
        <SelectGroup
          aria-label={t('models.user.month')}
          data-cy={'anniversaryMonth'}
          isClearable={!signUpSetting.isSignUpAnniversaryRequired}
          field={'weddingMonth'}
          options={monthDayOptions(t, values, 'anniversary').months}
          title={t('models.user.month')}
          size="lg"
          validate={validateMonthDate(
            signUpSetting.isSignUpAnniversaryRequired,
            'anniversaryMonth',
            basicFormApi,
          )}
        />
      </Grid>
      <Grid item xs={5}>
        <SelectGroup
          aria-label={t('models.user.day')}
          data-cy={'anniversaryDay'}
          isClearable={!signUpSetting.isSignUpAnniversaryRequired}
          field={'weddingDay'}
          options={monthDayOptions(t, values, 'anniversary').days}
          title={t('models.user.day')}
          validate={validateMonthDate(
            signUpSetting.isSignUpAnniversaryRequired,
            'anniversaryDay',
            basicFormApi,
          )}
          size="lg"
        />
      </Grid>
    </Grid>
  );
};

export default AnniversaryFields;
