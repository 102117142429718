import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@popmenu/admin-ui';
import { Avatar, Box, Link, Typography } from '@popmenu/common-ui';

import { createEvent } from '~/utils/eventable';
import { withStyles, classNames } from '../../utils/withStyles';
import { setVipData } from '../ModalActions';
import styles from './styles';

const NewFollowerInfoPage = (props) => {
  const dispatch = useDispatch();
  const userName = props.userData.displayName ? `, ${props.userData.displayName}` : '';
  const isOnOloPage = useSelector(state => state.modals.isOnOloPage);
  const vipData = useSelector(state => state.modals.vipData);
  const { avatarUrl, followedRestaurantName } = props.userData;
  const { offerMessageCampaignCount } = props;
  const hasNewFollowerOffer = isOnOloPage && offerMessageCampaignCount > 0;
  const offerMessageText = offerMessageCampaignCount === 1 ? ' and apply this offer to your order' : ' and apply one of them to your order';
  const userInput = vipData.userEmail ? { userEmail: vipData.userEmail } : { userPhone: vipData.userPhone };

  return (
    <Box
      marginX={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
    >
      <Avatar
        alt={userName || 'avatar'}
        className={props.classes.avatar}
        src={avatarUrl}
      />
      <Typography className={props.classes.header} id="become-vip-title" aria-live="polite" role="status">
        <FormattedMessage
          id="follower.info.title"
          defaultMessage={`Hi${userName}!`}
        />
      </Typography>
      {hasNewFollowerOffer && (
        <Typography className={props.classes.header2} id="become-vip-description" aria-live="polite" role="status">
          <FormattedMessage
            id="follower.info.offer_available"
            defaultMessage="You have {offerMessageCampaignCount} available {offer}!"
            values={{ offer: offerMessageCampaignCount === 1 ? 'offer' : 'offers', offerMessageCampaignCount }}
          />
        </Typography>
      )}
      <Typography
        align="center"
        className={classNames(props.classes.subheader, props.classes.subheaderEmail)}
      >
        {props.userEmail}
      </Typography>
      <Box marginBottom={4}>
        <Typography variant="body1" className={props.classes.subheader}>
          <FormattedMessage
            id="follower.info.subtitle"
            defaultMessage={'You already have a VIP account with another Popmenu restaurant partner{partnerRestaurant}.'}
            values={{ partnerRestaurant: followedRestaurantName ? ` (${followedRestaurantName})` : null }}
          />
        </Typography>
      </Box>
      <Box marginBottom={4}>
        <Typography variant="body1" className={props.classes.subheader}>
          <FormattedMessage
            id="follower.info.subtitle_question"
            defaultMessage={`Would you like to use the same login to Become a VIP of ${props.restaurantName}{offer}?`}
            values={{ offer: hasNewFollowerOffer && offerMessageText }}
          />
        </Typography>
      </Box>
      <Button
        className={props.classes.infoPageButton}
        variant="primary"
        fullWidth
        onClick={() => {
          createEvent({
            eventableType: 'BecomeVipV2',
            eventType: 'v2_same_login_info',
          });
          props.showSignInForm();
          dispatch(setVipData({ isBecomeVipModal: true, ...userInput }));
        }}
        data-cy="use_same_login"
      >
        <FormattedMessage
          id="follower.info.continue_button_text"
          defaultMessage="Yes, use the same login"
        />
      </Button>
      <Button
        className={props.classes.infoPageButton}
        color="primary"
        onClick={() => {
          createEvent({
            eventableType: 'BecomeVipV2',
            eventType: 'v2_different_login_info',
          });
          props.showSignUpForm();
        }}
        fullWidth
        variant="outlined"
      >
        <FormattedMessage
          id="follower.info.create_new_account_button_text"
          defaultMessage="No, use a different login"
        />
      </Button>
      <Box>
        <Link
          className={props.classes.popmenuLink}
          href="https://get.popmenu.com/"
          target="_blank"
          onClick={() => {
            createEvent({
              eventableType: 'BecomeVipV2',
              eventType: 'v2_what_is_pop_menu_link',
            });
          }}
        >
          <FormattedMessage
            id="follow.info.popmenu_link"
            defaultMessage="What is Popmenu?"
          />
        </Link>
      </Box>
    </Box>
  );
};

NewFollowerInfoPage.defaultProps = {
  style: null,
};

NewFollowerInfoPage.propTypes = {
  restaurantName: PropTypes.string.isRequired,
  showSignInForm: PropTypes.func.isRequired,
  showSignUpForm: PropTypes.func.isRequired,
  style: PropTypes.object,
  userData: PropTypes.object.isRequired,
  userEmail: PropTypes.string.isRequired,
};

export default withStyles(styles)(NewFollowerInfoPage);
